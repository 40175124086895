import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { LookUpComponent } from '@request/look-up/look-up.component';

import { TAGLookUpEntity } from './tag-lookup-entity';

@Injectable({
  providedIn: 'root',
})
export class LookUpService {
  dynamicData: any[] = [];
  dialogRef_: MatDialogRef<any> | undefined;

  constructor(public dialog: MatDialog) {}

  lookUp<T>(entity: TAGLookUpEntity, filteredValue?: string | null): Observable<Record<any, any> | undefined> {
    return new Observable<Record<any, any> | undefined>((observer) => {
      const dialogRef = this.dialog.open(LookUpComponent, {
        panelClass: ['Zoom-out', 'animated', 'dynamic-look-up'],
        data: { entity, filteredValue },
        position: { top: '100px' },
      });
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          observer.next(result);
          observer.complete();
        });
    });
  }
}

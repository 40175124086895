import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { Employee, Facility, WorkOrderLine } from '@tag/graphql';
import { DxDataGridComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/operators';

import translation from '@tag/i18n/src/languages/en.json';
import { DxModule } from '@app/dx.module';
import { MatDesignModule } from '@app/mat-design.module';
import { BarcodeReaderService } from '@components/barcode-reader/barcode-reader.service';
import { UserInfo } from '@api/types';
import { TabHeadersService } from '@request/services/tab-headers.service';
import { TAGLookUpEntity } from '@request/services/tag-lookup-entity';
import { ConsumablesGQL } from '@shared/apollo/queries/consumable';
import { EquipmentsGQL } from '@shared/apollo/queries/equipment';
import { ItemsGQL } from '@shared/apollo/queries/item';
import { PersonnelsGQL } from '@shared/apollo/queries/personnel';
import { RequestsGQL } from '@shared/apollo/queries/request';
import {
  PlannedWorkOrdersGQL,
  WorkOrdersGQL,
} from '@shared/apollo/queries/work-order';
import { PaginationArgs } from '@shared/grids/grid-template/grid-template.component';
import { GridPaginationService } from '@shared/grids/services/grid-pagination.service';
import { GetTaEmployees } from '@stores-actions/time-and-attendance.action';
import {
  GetWorkOrderLines,
  GetWorkOrderLinesByWorkOrder,
} from '@stores-actions/work-order-line.action';
import { AuthState } from '@stores-states/authentication.state';
import { WorkOrderLineState } from '@stores-states/work-order-line.state';

import { TimeAndAttendanceState } from '../../stores/states/time-and-attendance.state';
import { GridPaginatedFilter } from '@shared/grids/types/grid-field';
import { HttpClient } from '@angular/common/http';
import { MaintenanceTypesGQL } from '@shared/apollo/queries/maintenance-type';
import { OrderTypesGQL } from '@shared/apollo/queries/order-type';
import { OutsideServiceProvidersGQL } from '@shared/apollo/queries/osp';
import { ProblemCodesGQL } from '@shared/apollo/queries/problem-code';
import { PartsGQL } from '@shared/apollo/queries/parts';
import { ToolsGQL } from '@shared/apollo/queries/tool';
import { FacilitiesGQL } from '@shared/apollo/queries/facility';
import { JobsGQL } from '@shared/apollo/queries/job';
import { StatusCodesGQL } from '@shared/apollo/queries/status-code';
import { UnitOfMeasuresGQL } from '@shared/apollo/queries/unit-of-measure';
import { EquipmentGroupsGQL } from '@shared/apollo/queries/equipment-group';
import { FailureCodesGQL } from '@shared/apollo/queries/failure-code';
import { ReasonCodesGQL } from '@shared/apollo/queries/reason-code';
import { JobTasksGQL } from '@shared/apollo/queries/job-task';
import { WorkCodesGQL } from '@shared/apollo/queries/work-code';
import { AreasGQL } from '@shared/apollo/queries/area';
import { WorkProceduresGQL } from '@shared/apollo/queries/work-procedure';
import { WorkOrderTemplatesGQL } from '@shared/apollo/queries/work-order-template';
import { VendorsGQL } from '@shared/apollo/queries/vendor';
import { LocationsGQL } from '@shared/apollo/queries/location';
import { DocumentTrackingsGQL } from '@shared/apollo/queries/document-tracking';
import { PersonnelGroupsGQL } from '@shared/apollo/queries/personnel-group';
import { BinsGQL } from '@shared/apollo/queries/bin';
import { GlAccountsGQL } from '@shared/apollo/queries/gl-account';
import { MainCodesGQL } from '@shared/apollo/queries/main-code';
import { RegionsGQL } from '@shared/apollo/queries/region';
import { UniversalDocumentNosGQL } from '@shared/apollo/queries/universal-document-no';
import { LinesGQL } from '@shared/apollo/queries/line';
import { environment } from '@environments/environment';
import { componentAnimation } from '@animations/component-animation';
import { FormStatusGQL } from '@shared/apollo/queries/forms-status';

interface LookupColumn {
  fieldName: string;
  filterKey?: string;
  displayName?: keyof typeof translation;
  type?: string;
}

@Component({
  standalone: true,
  imports: [CommonModule, TranslocoModule, DxModule, MatDesignModule],
  selector: 'app-look-up',
  templateUrl: './look-up.component.html',
  styleUrls: ['./look-up.component.css'],
  animations: [componentAnimation],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookUpComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('lookUpGrid') lookUpGrid: DxDataGridComponent | undefined;
  @Input() inputEntity: TAGLookUpEntity | null = null;
  @Input() inputFilter: string | null = null;

  @Output() selectRecord = new EventEmitter();
  @Output() barcodeScanned = new EventEmitter();

  dataSource: any[] = [];
  columnNames: LookupColumn[] = [];
  keyExpression: string[] = [];
  lookupTitle?: keyof typeof translation;
  checkBoxesMode: string;

  delayRender = true;

  selectedRows: number[] = [];
  selectionChangedBySelectbox = false;

  // New GraphQL Fields
  subscription?: Subscription;
  paginationFilter = new Map<string, GridPaginatedFilter>();
  pagination: PaginationArgs = {
    pageSize: 25,
    pageIndex: 0,
    totalCount: 0,
    filter: '',
    search: '',
    sort: {
      desc: false,
      field: '',
    },
    pageSizeOptions: [5, 10, 25, 50],
  };
  useGraphQL = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { entity: TAGLookUpEntity; filteredValue: string },
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<LookUpComponent>,
    private maintenanceTypesService: MaintenanceTypesGQL,
    private orderTypesService: OrderTypesGQL,
    private barcodeReaderService: BarcodeReaderService,
    // private itemIdentifierService: ItemIdentifierService,
    private outsideServiceProvidersService: OutsideServiceProvidersGQL,
    private personnelsService: PersonnelsGQL,
    private problemCodesService: ProblemCodesGQL,
    private partsService: PartsGQL,
    private toolsService: ToolsGQL,
    private consumableService: ConsumablesGQL,
    private facilitiesService: FacilitiesGQL,
    private itemsService: ItemsGQL,
    private jobsService: JobsGQL,
    private statusCodesService: StatusCodesGQL,
    private measureUnitsService: UnitOfMeasuresGQL,
    private regionsService: RegionsGQL,
    private equipmentGroupsService: EquipmentGroupsGQL,
    private equipmentFailureCodesService: FailureCodesGQL,
    private reasonCodeService: ReasonCodesGQL,
    private jobTaskLinesService: JobTasksGQL,
    private workCodesService: WorkCodesGQL,
    private equipmentsService: EquipmentsGQL,
    private readonly store: Store,
    private areasService: AreasGQL,
    private lineService: LinesGQL,
    private workOrdersService: WorkOrdersGQL,
    private plannedWorkOrdersService: PlannedWorkOrdersGQL,
    private workProceduresService: WorkProceduresGQL,
    private webTemplateService: WorkOrderTemplatesGQL,
    private tabHeadersService: TabHeadersService,
    private vendorsService: VendorsGQL,
    private locationsService: LocationsGQL,
    private udnsService: UniversalDocumentNosGQL,
    private personnelGroupsService: PersonnelGroupsGQL,
    private binContentsService: BinsGQL,
    private requestsService: RequestsGQL,
    private glAccountsService: GlAccountsGQL,
    private mainCodesService: MainCodesGQL,
    private gridPaginationService: GridPaginationService,
    private formStatusGQL: FormStatusGQL,
    private httpClient: HttpClient
  ) {
    this.checkBoxesMode = 'none';
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  get userInfo(): UserInfo | null {
    return this.store.selectSnapshot(AuthState.userInfo);
  }

  get portalType(): string | null {
    return this.store.selectSnapshot(AuthState.portalType);
  }

  ngOnInit(): void {
    // If the component is initialized from the DOM, then use the data from the input.
    if (this.inputEntity) return;

    // TODO ETVSD Hot fix for the binCodes lookup we need to think a better way to handle this
    this.initDxGridRowFilterWithInitalFilterValue(
      [TAGLookUpEntity.binCodes],
      this.data
    );

    this.populateLookUp(this.data.entity, this.data.filteredValue);
  }

  // TODO ETVSD Hot fix for the binCodes lookup rowfilter we need to think a better way to handle this
  private initDxGridRowFilterWithInitalFilterValue(
    forEntities: TAGLookUpEntity[],
    data: { entity: TAGLookUpEntity; filteredValue: string }
  ) {
    if (forEntities.includes(data.entity) && data.filteredValue) {
      //regex to extract the Key and value from the filter like "Item_No eq 'LS-75'" => value['Item_No eq 'LS-75'','Item_No', 'LS-75']
      const regex = /(\w+_\w+)\s+eq\s+'([^']+)'/;
      const match = regex.exec(data.filteredValue ?? '');
      if (!match) return;

      this.pagination.filter = data.filteredValue;

      //set the row filter field with value
      this.paginationFilter.set(match[1], {
        operation: '=',
        value: match[2],
        type: 'string',
      });
    }
  }

  ngOnChanges(): void {
    this.dataSource = [];

    // Reset the pagination
    this.pagination.pageIndex = 0;
    this.pagination.totalCount = 0;
    this.pagination.filter = '';

    const entity = this.inputEntity ?? this.data.entity;
    const filter = this.inputFilter ?? this.data.filteredValue;
    this.populateLookUp(entity, filter);

    this.delayRender = false;
    setTimeout(() => {
      this.delayRender = true;
    }, 500);
  }

  selectRow(selectedRow: any): void {
    switch (this.checkBoxesMode) {
      case 'none':
        this.selectRowModeNone(selectedRow);
        break;
      case 'onClick':
        break;
    }
  }

  onToolbarPreparing(event: any): void {
    if (
      this.inputEntity === TAGLookUpEntity.items ||
      this.data.entity === TAGLookUpEntity.items
    )
      event.toolbarOptions.items.unshift({
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'photo',
          onClick: (): void => this.displayBarcodeReader(),
        },
      });
    if (
      this.data.entity === TAGLookUpEntity.equipementPerRequest &&
      this.checkBoxesMode === 'onClick'
    )
      event.toolbarOptions.items.unshift({
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'check',
          onClick: (): void => this.selectRowModeOnClick(),
        },
      });
  }

  displayBarcodeReader(): void {
    this.barcodeReaderService
      .launchBarcodeReader()
      .pipe(take(1))
      .subscribe((code: string) => {
        this.barcodeScanned.emit(code);
      });
  }

  // GraphQL Methods
  searchChange(value: string): void {
    this.pagination.search = value;
    this.populateLookUp(
      this.inputEntity ?? this.data.entity,
      this.inputFilter ?? this.data.filteredValue
    );
  }

  onPaginationChanging(e: PageEvent): void {
    this.pagination.pageSize = e.pageSize;
    this.pagination.pageIndex = e.pageIndex;

    this.populateLookUp(
      this.inputEntity ?? this.data.entity,
      this.inputFilter ?? this.data.filteredValue
    );
  }

  onEditorPreparing(e: any): void {
    if (e.parentType === 'filterRow') {
      e.updateValueTimeout = 2000;
    }
  }

  onUpdateFilterOperation(row: LookupColumn, operation: string): void {
    const filterField = row.filterKey || row.fieldName;
    const filter = this.paginationFilter.get(filterField);
    if (filter) {
      filter.operation = operation;
      this.pagination.filter =
        this.gridPaginationService.convertFilterToOdataFilter(
          this.paginationFilter
        );
      this.populateLookUp(
        this.inputEntity ?? this.data.entity,
        this.inputFilter ?? this.data.filteredValue
      );
    } else {
      this.paginationFilter.set(filterField, {
        operation,
        value: '',
        type: 'string',
      });
    }
  }

  onUpdateFilterValue(row: LookupColumn, value: string): void {
    const filterField = row.filterKey || row.fieldName;
    const filter = this.paginationFilter.get(filterField);
    if (filter) {
      filter.value = value;
    } else {
      const defaultFilter =
        this.gridPaginationService.getDefaultFilterOperation('string');
      this.paginationFilter.set(filterField, {
        operation: defaultFilter,
        value,
        type: 'string',
      });
    }

    // Back to first page when filter is updated (to avoid pagination issues)
    this.pagination.pageIndex = 0;
    this.pagination.filter =
      this.gridPaginationService.convertFilterToOdataFilter(
        this.paginationFilter
      );
    this.populateLookUp(
      this.inputEntity ?? this.data.entity,
      this.inputFilter ?? this.data.filteredValue
    );
  }

  private populateLookUp(
    tagLookupEntity: TAGLookUpEntity,
    filteredValue?: string
  ): void {
    this.subscription?.unsubscribe();
    this.lookUpGrid?.instance.beginCustomLoading('Loading...');
    this.dataSource = [];

    const ospFilter = `Blocked eq ''`;
    const statusFilter = filteredValue ? `Type eq '${filteredValue}'` : '';
    const wpFilter = filteredValue
      ? `Input_Type eq 'Meter Reading' or Input_Type eq 'Meter Reset'`
      : '';
    const fcFilter = filteredValue ? `Equipment_ID eq '${filteredValue}'` : '';
    const itemNo = filteredValue ?? '';

    switch (tagLookupEntity) {
      case TAGLookUpEntity.parts:
        this.columnNames = [
          { fieldName: 'no', displayName: 'numKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
          {
            fieldName: 'equipmentId',
            displayName: 'equipmentIdKey',
            type: 'string',
          },
          {
            fieldName: 'variantCode',
            displayName: 'variantCodeKey',
            type: 'string',
          },
          {
            fieldName: 'details',
            displayName: 'detailsKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['no', 'description', 'variantCode', 'details'];
        this.lookupTitle = 'partsKey';

        const partFilter = filteredValue
          ? [this.pagination.filter, `Equipment_ID eq '${filteredValue}'`]
              .filter(Boolean)
              .join(' and ')
          : this.pagination.filter;

        this.subscription = this.partsService
          .watch({
            filter: partFilter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.parts))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.tools:
        this.columnNames = [
          { fieldName: 'no', displayName: 'numKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
          {
            fieldName: 'Tool Cost',
            displayName: 'toolCostKey',
            type: 'number',
          },
        ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'toolsKey';

        this.subscription = this.toolsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.tools))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.consumables:
        if (!this.pagination.filter)
          this.columnNames = [
            { fieldName: 'no', displayName: 'numKey', type: 'string' },
            {
              fieldName: 'description',
              displayName: 'descriptionKey',
              type: 'string',
            },
            {
              fieldName: 'navisionQuantityOnHand',
              displayName: 'availableQuantityKey',
              type: 'string',
            },
            {
              fieldName: 'Cost When Consumed',
              displayName: 'costWhenConsumedKey',
              type: 'number',
            },
          ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'consumableKey';

        this.subscription = this.consumableService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.consumables))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.osp:
        this.columnNames = [
          { fieldName: 'no', displayName: 'numKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
          {
            fieldName: 'NAV Vendor No.',
            displayName: 'NAVVendorNoKey',
            type: 'string',
          },
          {
            fieldName: 'chargeType',
            displayName: 'chargeTypeKey',
            type: 'string',
          },
          {
            fieldName: 'hourlyRate',
            displayName: 'hourlyRateKey',
            type: 'number',
          },
          {
            fieldName: 'flatRateCharge',
            displayName: 'flatRateChargeKey',
            type: 'number',
          },
        ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'outsideServiceProvidersKey';
        this.subscription = this.outsideServiceProvidersService
          .watch({
            filter: [this.pagination.filter, "Blocked eq ''"]
              .filter(Boolean)
              .join(' and '),
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(
            map((result) => result.data.outsideServiceProviders)
          )
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.items:
        this.lookupTitle = 'itemsKey';
        this.keyExpression = ['no'];
        if (!this.pagination.filter)
          this.columnNames = [
            { fieldName: 'no', displayName: 'numKey', type: 'string' },
            {
              fieldName: 'description',
              displayName: 'descriptionKey',
              type: 'string',
            },
          ];

        this.subscription = this.itemsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.items))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.problemCodes:
        this.columnNames = [
          {
            fieldName: 'problemCode',
            displayName: 'codeKey',
            type: 'string',
          },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.lookupTitle = 'problemCodesKey';
        this.keyExpression = ['problemCode'];

        this.subscription = this.problemCodesService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.problemCodes))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.employees:
        this.store.dispatch(new GetTaEmployees());
        this.columnNames = [
          {
            fieldName: 'employeeNo',
            displayName: 'noKey',
            type: 'string',
          },
          {
            fieldName: 'name',
            displayName: 'nameKey',
            type: 'string',
          },
          {
            fieldName: 'resourceNo',
            displayName: 'resourceNoKey',
            type: 'string',
          },
          {
            fieldName: 'employeeGroupCode',
            displayName: 'groupKey',
            type: 'string',
          },
          {
            fieldName: 'regionCode',
            displayName: 'regionKey',
            type: 'string',
          },
        ];
        this.lookupTitle = 'employeesKey';
        this.keyExpression = ['employeeNo'];
        this.subscription = this.store
          .select(TimeAndAttendanceState.getEmployees)
          .subscribe((emps: Employee[]) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = emps;
          });
        break;

      case TAGLookUpEntity.maintenanceCodes:
        this.columnNames = [
          {
            fieldName: 'code',
            displayName: 'codeKey',
            type: 'string',
          },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.lookupTitle = 'maintenanceCodeKey';
        this.keyExpression = ['code'];
        this.subscription = this.mainCodesService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.mainCodes))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.status:
        this.columnNames = [
          {
            fieldName: 'statusCode',
            displayName: 'codeKey',
            type: 'string',
          },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['statusCode'];
        this.lookupTitle = 'statusKey';

        this.subscription = this.statusCodesService
          .watch({
            filter: [statusFilter, this.pagination.filter]
              .filter(Boolean)
              .join(' and '),
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.statusCodes))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.orderTypes:
        this.lookUpGrid?.instance.endCustomLoading();
        this.columnNames = [
          { fieldName: 'code', displayName: 'codeKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['code'];
        this.lookupTitle = 'orderTypesKey';

        this.subscription = this.orderTypesService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.orderTypes))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();

            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.unitsOfMeasure:
        this.columnNames = [
          { fieldName: 'code', displayName: 'codeKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['code'];

        this.lookupTitle = 'measureUnitsKey';
        this.subscription = this.measureUnitsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.unitOfMeasures))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.equipments:
        if (!this.pagination.filter)
          this.columnNames = [
            {
              fieldName: 'id',
              filterKey: 'ID',
              displayName: 'idKey',
              type: 'string',
            },
            {
              fieldName: 'description',
              displayName: 'descriptionKey',
              type: 'string',
            },
          ];
        this.keyExpression = ['id'];
        this.lookupTitle = 'equipmentsKey';

        this.subscription = this.equipmentsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            search: this.pagination.search,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
          })
          .valueChanges.pipe(map((result) => result.data.equipments))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.equipementPerRequest:
        if (!this.pagination.filter)
          this.columnNames = [
            { fieldName: 'id', displayName: 'idKey', type: 'string' },
            {
              fieldName: 'description',
              displayName: 'descriptionKey',
              type: 'string',
            },
          ];
        this.checkBoxesMode = 'onClick';
        this.keyExpression = ['id'];
        this.lookupTitle = 'equipmentsKey';

        this.subscription = this.equipmentsService
          .watch({
            filter: this.pagination.filter
              ? 'Requests_Element gt 0 and ' + this.pagination.filter
              : 'Requests_Element gt 0',
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.equipments))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.glAccounts:
        this.columnNames = [
          { fieldName: 'no', displayName: 'numKey', type: 'string' },
          { fieldName: 'name', displayName: 'nameKey', type: 'string' },
        ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'glAccountsKey';

        this.subscription = this.glAccountsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.glAccounts))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.personnels:
        if (!this.pagination.filter)
          this.columnNames = [
            { fieldName: 'no', displayName: 'numKey', type: 'string' },
            { fieldName: 'name', displayName: 'nameKey', type: 'string' },
            {
              fieldName: 'personnelGroup',
              displayName: 'personnelGroupKey',
              type: 'string',
            },
          ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'personnelsKey';

        this.subscription = this.personnelsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.personnels))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.personnelGroups:
        this.columnNames = [
          { fieldName: 'code', displayName: 'codeKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['code'];
        this.lookupTitle = 'personnelGroupKey';

        this.subscription = this.personnelGroupsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.personnelGroups))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.workProcedures:
        this.columnNames = [
          {
            fieldName: 'no',
            displayName: 'numKey',
            type: 'string',
          },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['workCode'];
        this.lookupTitle = 'workProcedureKey';

        this.subscription = this.workProceduresService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.workProcedures))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.workOrderTemplates:
        this.columnNames = [
          {
            fieldName: 'no',
            displayName: 'numKey',
            type: 'string',
          },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'workOrderFromTemplateKey';

        this.subscription = this.webTemplateService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.workOrderTemplates))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.workCodes:
        this.columnNames = [
          {
            fieldName: 'workCode',
            displayName: 'codeKey',
            type: 'string',
          },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['workCode'];
        this.lookupTitle = 'workCodesKey';
        this.subscription = this.workCodesService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.workCodes))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.maintenanceTypes:
        this.columnNames = [
          { fieldName: 'code', displayName: 'codeKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['code'];
        this.lookupTitle = 'maintenanceTypesKey';
        this.subscription = this.maintenanceTypesService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.maintenanceTypes))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.failureCodes:
        this.columnNames = [
          { fieldName: 'failureCode', displayName: 'codeKey', type: 'string' },
          {
            fieldName: 'failureCodeDescription',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['failureCode'];
        this.lookupTitle = 'failureCodesKey';

        this.subscription = this.equipmentFailureCodesService
          .watch({
            filter: fcFilter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.equipmentFailureCodes))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.reasonCodes:
        this.columnNames = [
          { fieldName: 'code', displayName: 'codeKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['code'];
        this.lookupTitle = 'reasonCodesKey';

        this.subscription = this.reasonCodeService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.reasonCodes))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.workOrderLines:
        this.columnNames = [
          {
            fieldName: 'workOrderNo',
            displayName: 'workOrderNoKey',
            type: 'string',
          },
          { fieldName: 'lineNo', displayName: 'lineNoKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['workOrderNo'];
        this.lookupTitle = 'workOrderLinesKey';

        filteredValue
          ? this.store.dispatch(new GetWorkOrderLinesByWorkOrder(filteredValue))
          : this.store.dispatch(new GetWorkOrderLines());

        // eslint-disable-next-line no-case-declarations
        const obs$ = filteredValue
          ? this.store.select(
              WorkOrderLineState.getWorkOrderLinesByWorkOrder(filteredValue)
            )
          : this.store.select(WorkOrderLineState.getWorkOrderLines);

        this.subscription = obs$.subscribe((wol: WorkOrderLine[]) => {
          this.lookUpGrid?.instance.endCustomLoading();
          this.dataSource = wol;
        });

        break;

      case TAGLookUpEntity.facilities:
        this.columnNames = [
          { fieldName: 'code', displayName: 'codeKey', type: 'string' },
          { fieldName: 'name', displayName: 'nameKey', type: 'string' },
          {
            fieldName: 'enterprise',
            displayName: 'enterpriseKey',
            type: 'string',
          },
        ];
        this.lookupTitle = 'facilitiesKey';
        this.keyExpression = ['code'];

        this.subscription = this.facilitiesService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.facilities))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.lines:
        this.columnNames = [
          { fieldName: 'code', displayName: 'codeKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
          {
            fieldName: 'facility',
            displayName: 'facilityKey',
            type: 'string',
          },
          {
            fieldName: 'area',
            displayName: 'areaKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['code'];
        this.lookupTitle = 'lineKey';

        const areaFilter = filteredValue ? `Area eq '${filteredValue}'` : '';

        this.subscription = this.lineService
          .watch({
            filter: [this.pagination.filter, areaFilter]
              .filter(Boolean)
              .join(' and '),
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.lines))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.areas:
        this.columnNames = [
          { fieldName: 'code', displayName: 'codeKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
          {
            fieldName: 'facility',
            displayName: 'facilityKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['code'];
        this.lookupTitle = 'areasKey';

        this.subscription = this.areasService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.areas))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.workOrders:
        if (!this.pagination.filter)
          this.columnNames = [
            { fieldName: 'no', displayName: 'numKey', type: 'string' },
            {
              fieldName: 'description',
              displayName: 'descriptionKey',
              type: 'string',
            },
          ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'workOrdersKey';

        this.subscription = this.workOrdersService
          .watch({
            filter: `Approval_Pending eq false ${
              this.pagination.filter ? ' and ' + this.pagination.filter : ''
            }`,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.workOrders))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.plannedWorkOrders:
        if (!this.pagination.filter)
          this.columnNames = [
            { fieldName: 'no', displayName: 'numKey', type: 'string' },
            {
              fieldName: 'description',
              displayName: 'descriptionKey',
              type: 'string',
            },
          ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'plannedWorkOrdersKey';

        this.subscription = this.plannedWorkOrdersService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.plannedWorkOrders))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.requests:
        if (!this.pagination.filter)
          this.columnNames = [
            { fieldName: 'no', displayName: 'numKey', type: 'string' },
            {
              fieldName: 'description',
              displayName: 'descriptionKey',
              type: 'string',
            },
          ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'requestsKey';

        this.subscription = this.requestsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            search: this.pagination.search,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
          })
          .valueChanges.pipe(map((result) => result.data.requests))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.equipmentGroups:
        this.columnNames = [
          { fieldName: 'code', displayName: 'codeKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['code'];
        this.lookupTitle = 'equipmentGroupsKey';

        this.subscription = this.equipmentGroupsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.equipmentGroups))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });

        break;

      case TAGLookUpEntity.regions:
        this.columnNames = [
          { fieldName: 'code', displayName: 'codeKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['code'];
        this.lookupTitle = 'regionsKey';

        this.subscription = this.regionsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.regions))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.vendors:
        this.columnNames = [
          { fieldName: 'no', displayName: 'noKey', type: 'string' },
          {
            fieldName: 'name',
            displayName: 'nameKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'vendorsKey';

        this.subscription = this.vendorsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.vendors))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.binCodes:
        this.columnNames = [
          {
            fieldName: 'binCode',
            displayName: 'binCodeKey',
            type: 'string',
            filterKey: 'Bin_Code',
          },
          {
            fieldName: 'itemNo',
            displayName: 'itemKey',
            type: 'string',
            filterKey: 'Item_No',
          },
          {
            fieldName: 'locationCode',
            displayName: 'locationCodeKey',
            type: 'string',
            filterKey: 'Location_Code',
          },
          { fieldName: 'quantity', displayName: 'quantityKey', type: 'string' },
        ];
        this.keyExpression = ['binCode'];
        this.lookupTitle = 'binCodeKey';

        this.subscription = this.binContentsService
          .watch(
            {
              filter: this.pagination.filter,
              top: this.pagination.pageSize,
              skip: this.pagination.pageIndex * this.pagination.pageSize,
              orderBy: this.pagination.sort?.field,
              desc: this.pagination.sort?.desc,
              search: this.pagination.search,
            },
            {
              fetchPolicy: 'no-cache',
            }
          )
          .valueChanges.pipe(map((result) => result.data.binContents))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.locations:
        this.columnNames = [
          { fieldName: 'code', displayName: 'codeKey', type: 'string' },
          {
            fieldName: 'name',
            displayName: 'nameKey',
            type: 'string',
          },
        ];
        if (itemNo) {
          this.columnNames = [
            ...this.columnNames,
            {
              fieldName: 'qty',
              displayName: 'quantityKey',
              type: 'string',
            },
          ];
        }
        this.keyExpression = ['code'];
        this.lookupTitle = 'locationsKey';

        // if (itemNo) {
        //   this.subscription = combineLatest([
        //     this.locationsService.LocationsGet(this.company),
        //     this.itemsService.ItemsItemByLocationGet(this.company, itemNo),
        //   ])
        //     .pipe(take(1))
        //     .subscribe(([locations, items]) => {
        //       this.lookUpGrid?.instance.endCustomLoading();
        //       const itemsByLocation = items[0];
        //       this.dataSource = locations.map((location) => {
        //         if (location.code && itemsByLocation.inventory) {
        //           const locationInventory = itemsByLocation.inventory.find(
        //             (inv) => Object.keys(inv)[0] === location.code
        //           );
        //           if (locationInventory)
        //             return {
        //               ...location,
        //               qty: locationInventory[location.code],
        //             };
        //           else return { ...location, qty: 0 };
        //         }
        //         return { ...location, qty: 0 };
        //       });
        //       this.cachedItems.set('locations' + itemNo, this.dataSource);
        //     });
        // } else {
        this.subscription = this.locationsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.locations))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        // }
        break;

      case TAGLookUpEntity.udns:
        this.columnNames = [
          {
            fieldName: 'documentTrackingNo',
            displayName: 'documentTrackingNoKey',
            type: 'string',
          },
          { fieldName: 'no', displayName: 'noKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['documentTrackingNo'];
        this.lookupTitle = 'documentTrackingNoKey';

        this.udnsService
          .watch({
            filter: this.pagination.filter,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.universalDocumentNos))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.woJobTasks:
        if (!this.paginationFilter)
          this.columnNames = [
            { fieldName: 'jobNo', displayName: 'noKey', type: 'string' },
            {
              fieldName: 'jobTaskNo',
              displayName: 'taskKey',
              type: 'string',
            },
            {
              fieldName: 'jobTaskDescription',
              displayName: 'descriptionKey',
              type: 'string',
            },
          ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'jobTasksKey';

        this.subscription = this.workOrdersService
          .watch({
            filter: this.pagination.filter
              ? `Job_No ne '' and ${this.pagination.filter}`
              : `Job_No ne ''`,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            search: this.pagination.search,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
          })
          .valueChanges.pipe(map((result) => result.data.workOrders))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.jobs:
        this.columnNames = [
          { fieldName: 'no', displayName: 'noKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['no'];
        this.lookupTitle = 'jobsKey';
        this.useGraphQL = false;

        this.jobsService
          .watch({
            filter: `Status ne 'Completed' and Blocked eq ''`,
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
            search: this.pagination.search,
          })
          .valueChanges.pipe(map((result) => result.data.jobs))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.jobTasks:
        this.columnNames = [
          { fieldName: 'jobNo', displayName: 'noKey', type: 'string' },
          {
            fieldName: 'jobTaskNo',
            displayName: 'jobTaskNoKey',
            type: 'string',
          },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['jobNo'];
        this.lookupTitle = 'jobTasksKey';
        this.useGraphQL = false;

        this.jobTaskLinesService
          .watch({
            filter: [filteredValue, this.pagination.filter]
              .filter(Boolean)
              .join(' and '),
            top: this.pagination.pageSize,
            skip: this.pagination.pageIndex * this.pagination.pageSize,
            search: this.pagination.search,
            orderBy: this.pagination.sort?.field,
            desc: this.pagination.sort?.desc,
          })
          .valueChanges.pipe(map((result) => result.data.jobTaskLines))
          .subscribe(({ items, totalCount }) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = items;
            this.pagination.totalCount = totalCount;
          });
        break;

      case TAGLookUpEntity.forms:
        this.columnNames = [
          { fieldName: 'id', displayName: 'idKey', type: 'string' },
          { fieldName: 'name', displayName: 'nameKey', type: 'string' },
          {
            fieldName: 'description',
            displayName: 'descriptionKey',
            type: 'string',
          },
        ];
        this.keyExpression = ['id'];
        this.lookupTitle = 'formsKey';
        this.useGraphQL = false;

        this.httpClient
          .get<any>(`${environment.baseWS}/forms`, {
            headers: {
              Authorization: `Bearer ${this.store.selectSnapshot(
                AuthState.token
              )}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .pipe(take(1))
          .subscribe((forms) => {
            this.dataSource = forms;
          });

        break;
      case TAGLookUpEntity.formStatuses:
        this.columnNames = [
          { fieldName: 'name', displayName: 'nameKey', type: 'string' },
          {
            fieldName: 'sortSequence',
            displayName: 'sortSequenceKey',
            type: 'number',
          },
        ];

        this.keyExpression = ['id'];
        this.lookupTitle = 'statusKey';
        this.useGraphQL = false;

        this.formStatusGQL
          .watch()
          .valueChanges.pipe(map((res) => res.data.formStatuses.items))
          .subscribe((formStatuses) => {
            this.lookUpGrid?.instance.endCustomLoading();
            this.dataSource = formStatuses;
          });
        break;
    }
  }

  private selectRowModeNone(selectedRow: any): void {
    if (selectedRow.selectedRowsData.length) {
      if (this.inputEntity)
        return this.selectRecord.emit(selectedRow.selectedRowsData[0]);
      this.dialogRef.close(selectedRow.selectedRowsData[0]);
    } else {
      if (this.inputEntity)
        return this.selectRecord.emit(selectedRow.selectedRowsData[0]);
      this.dialogRef.close();
    }
  }

  private selectRowModeOnClick(): void {
    if (!this.lookUpGrid) return;
    if (this.inputEntity)
      return this.selectRecord.emit(
        this.lookUpGrid.instance.getSelectedRowsData()
      );
    this.dialogRef.close(this.lookUpGrid.instance.getSelectedRowsData());
  }
}
